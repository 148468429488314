import React, {useRef} from "react"
import emailjs from 'emailjs-com';
import "../styles/contact-form.scss";

import LinkedInLogo from "../icons/linkedin-box-line.svg";
import InstagramLogo from "../icons/instagram-line.svg";
import DiscordLogo from "../icons/discord-line.svg";
import GithubLogo from "../icons/github-line.svg";

const Contact = ({contactReference}) =>
{
    const sendButtonRef = useRef(null)
    const sendEmail = (e) =>
    {
        e.preventDefault();
        sendButtonRef.current.setAttribute("value", "Processing...");

        emailjs.sendForm('service_alkbb9i', 'template_c6at29h', e.target, 'user_JUrcTGgUraBmPkKkdIARe')
        .then((result) => {
            console.log(result.text);
            sendButtonRef.current.setAttribute("value", "Message sent!");
            sendButtonRef.current.setAttribute("disabled", "disabled");
        }, (error) => {
            console.log(error.text);
            sendButtonRef.current.setAttribute("value", "Failed to send. Please contact me with the information below.");
        });
    }

    let socials = [
        {
            svg: LinkedInLogo,
            link: "https://www.linkedin.com/in/joaoepe "
        }, 
        {
            svg:InstagramLogo, 
            link: "https://www.instagram.com/feldtsen/"
        }, 
        {
            svg:DiscordLogo,
            link: "https://discord.gg/atMZ8cGVNe"
        },
        {
            svg: GithubLogo,
            link: "https://github.com/feldtsen"
        }
    ];



    return (
        <form 
            className="contact" 
            onSubmit={sendEmail}
            autoComplete="off"
            ref={contactReference}
        
        >
            <div className="contact__entry">
                <input className="contact__input" placeholder=" " type="text" name="from_name" id="from_name" required/>
                <label htmlFor="from_name"><span>What's your name?</span></label>
            </div>
            
            <div className="contact__entry">
                <input className="contact__input" placeholder=" " type="email" name="reply_to" id="reply_to" required/>
                <label htmlFor="reply_to"><span>And email address?</span></label>
            </div>

            <div className="contact__entry">
                <textarea className="contact__input contact__input-textarea" placeholder=" " rows="4" name="message" id="message" required />
                <label htmlFor="message"><span>What's the deal?</span></label>
                <input type="submit" value="Send" ref={sendButtonRef} />
            </div>

            <div className="contact__alternative">
                <p className="contact__alternative">
                     or just contact me on <br/>
                    <a className="contact__alternative--email-highlight" href="mailto:feldtsen@gmail.com">feldtsen@gmail.com</a>
                </p>

                <div className="contact__alternative__social-media">

                    {
                        socials.map((social) => (
                            <div className="contact__alternative__social-media--icon" key={social.svg}>
                                <a href={social.link}>
                                    <img src={social.svg}/>
                                </a>
                            </div>
                        ))
                    }

                </div>
            </div>

            <input type="hidden" name="contact_number" />
        </form>

    )
}

export default Contact;
